<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 14:26:11
 * @LastEditors: 番茄
 * @LastEditTime: 2022-02-03 00:51:32
-->
<template>
  <div class="home-container">
    <nut-searchbar v-model="searchValue" background="rgb(255, 218, 49)" input-background="#fff" placeholder="搜点你想看的" @submit="searchBook">
      <template v-slot:rightout>
        <nut-icon name="search" @click="searchBook"></nut-icon>
      </template>
    </nut-searchbar>

    <div class="book-container">
      <div class="nodata" v-if="!bookList.length">
        <p>🍅</p>
        <p>书架为空，快去挑选几本你想看的书籍吧</p>
      </div>
      <div v-else class="book-content">
        <div class="book-box">
          <div v-for="(item, index) in bookList" :key="index" @click="toChapter(item, index)" @touchstart="touch(index)">
            <img :src="item.bookImg" alt="" />
            <div class="book-desc">
              <p class="title">{{ item.bookName }}</p>
              <p class="now-read">
                <span class="read-title">读到：{{ item.chapterName }}</span>
                <span class="continue-read" v-if="!touchShow">继续阅读＞</span>
                <nut-checkbox v-else v-model="item.checked" class="check-box"></nut-checkbox>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除书籍 -->
    <div v-if="touchShow" class="del-book-box" :class="[touchShow ? 'show-del' : '']">
      <span @click="cancelDel">取消</span>
      <span @click="okDel" :class="[delBookNum === 0 ? 'no-del-book' : 'ok-del-book']">删除({{ delBookNum }})</span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Notify, Dialog } from "@nutui/nutui";
import { getNotice } from "@/api/search";

export default {
  setup() {
    const searchValue = ref("");
    const bookList = ref([]);
    const touchShow = ref(false);

    const router = useRouter();
    onMounted(() => {
      bookList.value = JSON.parse(localStorage.getItem("bookList")) || [];
      if (bookList.value.length) {
        bookList.value.map((v) => (v.checked = false));
      }
      if (!localStorage.getItem("noticeFlag")) {
        getNotice().then((res) => {
          Dialog({
            content: res.data.noticeContent,
            noCancelBtn: true,
          });
          localStorage.setItem("noticeFlag", true);
        });
      }
    });

    const searchBook = () => {
      if (searchValue.value) {
        router.push({ path: "/search", query: { search: searchValue.value } });
      } else {
        Notify.warn("请先输入要搜索的书籍");
      }
    };

    const toChapter = (item, index) => {
      // 点击书籍
      if (touchShow.value) {
        bookList.value[index].checked = !bookList.value[index].checked;
        if (bookList.value[index].checked) {
          delBookNum.value++;
        } else {
          delBookNum.value--;
        }
        clearTimeout(timer.value);
      } else {
        router.push({
          name: "chapter",
          params: { bookItem: JSON.stringify(item), isFromHome: true },
        });
      }
    };

    const delBookNum = ref(1);
    const timer = ref(null);

    const touch = (index) => {
      timer.value = setTimeout(() => {
        if (!touchShow.value) {
          touchShow.value = true;
          bookList.value[index].checked = true;
        }
      }, 500);
    };

    // 取消删除
    const cancelDel = () => {
      touchShow.value = false;
      bookList.value.map((v) => (v.checked = false));
      delBookNum.value = 1;
    };
    // 确定删除
    const okDel = () => {
      Dialog({
        content: "确定删除所选书籍？",
        onOk() {
          const filterDelBook = bookList.value.filter((v) => !v.checked);
          bookList.value = [...filterDelBook];
          localStorage.setItem("bookList", JSON.stringify(bookList.value));
          touchShow.value = false;
        },
      });
    };

    return {
      searchValue,
      searchBook,
      bookList,
      toChapter,
      touch,
      touchShow,
      cancelDel,
      okDel,
      delBookNum,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .del-book-box {
    width: 100%;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    // border-top: 1px solid #dfdfdf;
    > span {
      // margin: 0 20px;
      flex: 0 0 50%;
      text-align: center;
      font-size: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .show-del {
    animation: show 0.4s ease forwards;
  }

  .no-del-book {
    color: #dfdfdf;
    pointer-events: none;
  }

  .ok-del-book {
    color: rgb(229, 158, 21);
  }
}

@keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 47px;
  }
}

.book-container {
  flex: 1;
  overflow: auto;
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    height: 100%;
    > p {
      margin-bottom: 10px;
      color: rgb(199, 88, 149);
    }
  }

  .book-content {
    padding: 20px;
    padding-bottom: 12.333vw;
    .book-box {
      font-size: 17px;

      > div {
        display: flex;
        margin-bottom: 18px;
        overflow: hidden;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
      }

      img {
        width: 60px;

        margin-right: 10px;
      }
    }
    .book-desc {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 18px 0;

      .title {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 255px;
        padding-top: 3px;
      }
      .now-read {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: rgb(160, 160, 160);
      }

      .read-title {
        display: inline-block;
        width: 170px;
        margin-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 2px;
      }

      .continue-read {
        flex: 1;
        color: rgb(229, 158, 21);
      }

      .check-box {
        position: absolute;
        right: -10px;
        top: 33px;
      }
    }
  }
}
</style>
